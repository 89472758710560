import Navigation from '../navigation/Navigation'

const Header = () => {
  return (
    <header>
      <Navigation />
    </header>
  )
}

export default Header
